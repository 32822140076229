exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-cs-11-applets-cs-11-applets-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/michael/Desktop/mogonzal/mogonzal-website/projects/cs11_applets/cs11_applets.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-cs-11-applets-cs-11-applets-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-cs-144-pandemaniac-cs-144-pandemaniac-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/michael/Desktop/mogonzal/mogonzal-website/projects/cs144_pandemaniac/cs144_pandemaniac.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-cs-144-pandemaniac-cs-144-pandemaniac-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-cs-145-whosecoin-cs-145-whosecoin-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/michael/Desktop/mogonzal/mogonzal-website/projects/cs145_whosecoin/cs145_whosecoin.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-cs-145-whosecoin-cs-145-whosecoin-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-me-14-octagon-me-14-octagon-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/michael/Desktop/mogonzal/mogonzal-website/projects/me14_octagon/me14_octagon.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-me-14-octagon-me-14-octagon-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-me-14-transmission-me-14-transmission-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/michael/Desktop/mogonzal/mogonzal-website/projects/me14_transmission/me14_transmission.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-me-14-transmission-me-14-transmission-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-me-72-competition-me-72-competition-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/michael/Desktop/mogonzal/mogonzal-website/projects/me72_competition/me72_competition.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-users-michael-desktop-mogonzal-mogonzal-website-projects-me-72-competition-me-72-competition-mdx" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

